import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Link } from 'react-router-dom';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import ContextMenu, { ContextMenuItem } from '../ContextMenu';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '../icon/DragHandleIcon';

type DataRowProps = {
  contextMenuItems?: ContextMenuItem[];
  selected?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
  hasChildren?: boolean;
  chevronStyle?: ChevronType;
  forceArrowSpacing?: boolean;
  url?: string;
} & (
  | {
      isSortingDisabled?: true;
    }
  | {
      sortableId: UniqueIdentifier;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sortableData?: Record<string, any>;
      isSortingDisabled: false;
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wrapper: FCWithChildren<{ url: string; innerRef: (element?: HTMLElement | null) => any }> = ({ url, innerRef, children, ...props }) =>
  url ? (
    <Link to={url} {...props} ref={innerRef}>
      {children}
    </Link>
  ) : (
    <div {...props} ref={innerRef}>
      {children}
    </div>
  );

const DataRow: FCWithChildren<DataRowProps> = (props) => {
  const {
    contextMenuItems,
    onClick,
    selected,
    disabled,
    title,
    hasChildren,
    children,
    chevronStyle = ChevronType.RIGHT,
    url,
    isSortingDisabled,
    forceArrowSpacing,
  } = props;

  const sortableId = isSortingDisabled === false ? props.sortableId : null;
  const sortableData = isSortingDisabled === false ? props.sortableData : undefined;

  const dataAttrs = dataAttributeProps(props);
  const { isDragging, transform, transition, setNodeRef, setActivatorNodeRef, attributes, listeners } = useSortable({
    id: sortableId || -1,
    disabled: isSortingDisabled,
    data: sortableData,
  });

  return (
    <Wrapper
      url={url}
      className={`hover:bg-accent-light-mid group flex items-center rounded-[10px] ${hasChildren ? 'bg-transparent' : 'bg-white'} mt-1 border-2 ${
        onClick || url ? 'cursor-pointer' : ''
      } relative transition-colors duration-300 ${isDragging || selected ? 'border-primary-2 z-50 shadow-xl' : 'border-gray-5'} ${
        disabled && 'bg-gray-5'
      }`}
      {...dataAttrs}
      innerRef={isSortingDisabled ? undefined : setNodeRef}
      style={{ transform: CSS.Translate.toString(transform), transition }}
      onClick={() => onClick && onClick()}
      title={title}
    >
      <div className={`flex w-full items-center ${isSortingDisabled !== undefined && isSortingDisabled !== true ? '' : 'pl-4'}`}>
        {isSortingDisabled !== undefined && !isSortingDisabled && (
          <div
            data-cy="drag-handle"
            className={`flex w-16 flex-shrink-0 items-center justify-center self-stretch p-4 outline-none transition-colors duration-300 ${
              isDragging ? 'text-primary-2 cursor-grabbing' : 'cursor-grab'
            }`}
            ref={isSortingDisabled ? undefined : setActivatorNodeRef}
            {...listeners}
            {...attributes}
          >
            <DragHandleIcon className="text-gray-2 h-4 w-4" />
          </div>
        )}

        <div className={`flex w-full items-center py-4 ${disabled && 'opacity-25'}`}>{children}</div>
        {contextMenuItems && (
          <div className="mr-2 flex w-8 flex-shrink-0 justify-end">
            <ContextMenu data-cy="context-menu" items={contextMenuItems} />
          </div>
        )}

        {(onClick || url || forceArrowSpacing) && (
          <div className={`w-10 ${forceArrowSpacing ? 'opacity-0' : ''}`}>
            <ChevronIcon type={chevronStyle} className="h-6 w-6 opacity-0 group-hover:opacity-100" />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default DataRow;
